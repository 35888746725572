<template>
  <v-card>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action }} Użytkownika</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Imię" v-model="firstName" required placeholder="Imię"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Nazwisko" v-model="lastName" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  placeholder="Nazwisko"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Telefon" v-model="phone" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  placeholder="Telefon"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="E-mail" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="email" v-model="email"
                  placeholder="E-mail"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :label="passwordLabel" v-model="password" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  type="password"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select :label="departamentLabel" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="departament"
                  :items="departaments" item-text="departamentName" item-value="id" multiple></v-select>
              </v-col>
              <v-col cols="12">
                <v-select label="Rola" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="role"
                  :items="roles" item-text="name" item-value="id"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="createUser()">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      Użytkownicy
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="showDialog()">Dodaj</v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"      
      :items="users"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      class="elevation-1"
    >
          <template v-slot:item.actions="{ item }">
            <v-btn class="primary" @click="getUserById(item.id)">
              <v-icon small class="pt-1">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.deleted="{ item }">
            <v-btn class="error" @click="deleteUser(item.id)">
              <v-icon small class="pt-1">mdi-delete</v-icon>
            </v-btn>
          </template>
  </v-data-table>          
  </v-card>   
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";

export default {
  name: "Users",
  data: () => ({
        dialog: false,
        action: "",
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        departament: "",
        departamentLabel: "",
        departaments:[],
        passwordLabel: "",
        phone: '',        
        currentUserId: null,    
        search: '',
        options: {},
        totalRecords:0,
        loading: false,
        headers: [
          {
            text: 'Imię',
            align: 'start',          
            value: 'firstName',
          },
          {
            text: 'Nazwisko',
            align: 'start',          
            value: 'lastName',
          },
          {
            text: 'Login',
            align: 'start',          
            value: 'email',
          }, 
          {
            text: 'Rola',
            align: 'start',
            value: 'role',
          },            
          { text: "", value: "actions", sortable: false },
          { text: "", value: "deleted", sortable: false },
        ],
          roles:[{
            id:'admin',
            name:'Admin/Zarząd'
          },
          {
            id: 'user',
            name: 'Office'
          }],
          role:'',
        users: []
  }),
  watch: {
    options: {
      async handler() {
        await this.loadUsers()
      },
      deep: true,
    },
  },  
  methods:{
    async showDialog(){
      await this.getDepartamentSelect()
      this.action = 'Tworzenie'
      this.dialog = true
      this.currentUserId = null
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = ""
      this.departament = null
      this.role = null
      this.password = "";                
      this.passwordLabel = 'Hasło'
      this.departamentLabel = 'Oddziały'
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },   
    async deleteUser(item) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Użytkownika?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
              await axios({
                method: "delete",
                url: process.env.VUE_APP_ROOT_API + "/users/" + item,
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data: {
                  status: false,
                },
              });
              await this.loadUsers();
            }
          })
          } catch (e) {
            console.log(e)
            Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
          }
      await this.toggleLoading(false);    
    },     
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/departaments/list-departaments?itemsPerPage=100&sort=departamentName&direction=false",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },   
    async getUserById(item) {      
      await this.getDepartamentSelect()
      await this.toggleLoading(true);      
      try {
        let user = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/users/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dialog = true;
        this.action = "Edycja";
        this.currentUserId = item;
        this.firstName = user.data.data.firstName;
        this.role = user.data.data.role;
        this.lastName = user.data.data.lastName;
        this.color = user.data.data.color;
        this.phone = user.data.data.phone;
        this.email = user.data.data.email;
        // this.warehouseLabel = "Nowy Magazyn";
        this.departament = user.data.data.departaments.map(item => item.id)
        this.passwordLabel = "Nowe Hasło";
      } catch (e) {
        console.log(e);
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + e.response.message[0], "error");
      }
      await this.toggleLoading(false);
    }, 
    async validateUserForm(){    
      if(!this.firstName.trim().length){
        throw ({ response: { statusText: 'Podaj Imię' } })
      }
      if(!this.lastName.trim().length){
        throw ({ response: { statusText: 'Podaj Nazwisko' } })
      }
      if(!this.email.trim().length){
        throw ({ response: { statusText: 'Podaj E-mail' } })
      }
      if(!this.phone.trim().length){
        throw ({ response: { statusText: 'Podaj Telefon' } })
      }
    },
    async createUser() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentUserId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/users/" + this.currentUserId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/auth/create-user";
      }     
      try {
        await this.validateUserForm()        
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },          
          data: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            role: this.role,            
            phone: this.phone,
            password: this.password || null,
            departaments: this.departament|| null,
          },
        });                
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = ""
        this.departament = null
        this.role = null
        this.password = "";
        this.dialog = false
        this.currentUserId = null;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }      
      await this.toggleLoading(false);
      await this.loadUsers()
    },       
    async loadUsers() {
      this.loading = true
      try{
        let { sortBy, sortDesc, page, itemsPerPage } = this.options                
        const users = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/users?sort=${sortBy.length?sortBy:'email'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });   
        users.data.data.items.forEach((item)=>{
          item.role = item.role=='user'?'Office':'Admin'
        })     
        this.users = users.data.data.items
        this.itemsPerPage = users.data.data.meta.itemsPerPage
        this.page = users.data.data.meta.currentPage   
        this.totalRecords = users.data.data.meta.totalItems         
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },    
  }
}
</script>
