<template>
    <v-card>
        <v-card-title>
            Dane z Importu            
          <v-spacer></v-spacer>
                <v-text-field label="Tydzień" v-model="weekNo" type="week" @change="getDashboardForDriver()"></v-text-field>
                <v-spacer></v-spacer>
                    <v-select label="Kierowca" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="driver"
                      :items="drivers" item-text="driverName" item-value="id" @change="getDashboardForDriver()"></v-select>        
        </v-card-title>        
        <v-card-text>
            <v-simple-table>
                    <thead>
                        <tr>
                            <th><h2>FreeNow</h2></th>                        
                                        <th>NieRozliczone</th>                        
                                        <th>rozliczone</th>                        
                                        <th>Suma</th>                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A) Saldo FREE NOW</td>
                            <td>{{ total_freenow_not_paid }}</td>
                            <td>{{ total_freenow_paid }}</td>
                            <td>{{ total_freenow_sum }}</td>
                        </tr>
                        <tr>
                            <td>B) Płatności gotówką/kartą</td>
                            <td>{{ cash_freenow_not_paid }}</td>
                            <td>{{ cash_freenow_paid }}</td>
                            <td>{{ cash_freenow_sum }}</td>
                        </tr>  
                        <tr>
                            <td>C) Bonusy</td>
                            <td>{{ bonus_freenow_not_paid }}</td>
                            <td>{{ bonus_freenow_paid }}</td>
                            <td>{{ bonus_freenow_sum }}</td>
                        </tr> 
                        <tr>
                            <td>D) Napiwki</td>
                            <td>{{ tip_freenow_not_paid }}</td>
                            <td>{{ tip_freenow_paid }}</td>
                            <td>{{ tip_freenow_sum }}</td>
                        </tr> 
                        <tr>
                            <td>D) Ilość Kursów</td>
                            <td>{{ tours_freenow_not_paid }}</td>
                            <td>{{ tours_freenow_paid }}</td>
                            <td>{{ tours_freenow_sum }}</td>
                        </tr>                          
                                    <tr>
                                        <td>Suma</td>
                                        <td>{{ sum_freenow_not_paid }}</td>
                                        <td>{{ sum_freenow_paid }}</td>
                                        <td>{{ sum_freenow_sum }}</td>
                                    </tr>                                                                                                                                                                       
                    </tbody>

                    <thead>
                        <tr>
                            <th><h2>Bolt</h2></th>                                 
                                        <th>NieRozliczone</th>                        
                                        <th>rozliczone</th>                        
                                        <th>Suma</th>                                                    
                        </tr>
                    </thead>
                    <tbody>
                            <tr>
                                <td>A) Wartość brutto</td>
                                            <td>{{ brutto_bolt_not_paid }}</td>
                                            <td>{{ brutto_bolt_paid }}</td>
                                            <td>{{ brutto_bolt_sum }}</td>
                            </tr>
                            <tr>
                                <td>B) Opłata za anulowanie</td>
                                            <td>{{ cancel_bolt_not_paid }}</td>
                                            <td>{{ cancel_bolt_paid }}</td>
                                            <td>{{ cancel_bolt_sum }}</td>                               
                            </tr>  
                            <tr>
                                <td>C) Opłata Bolt</td>
                                            <td>{{ fee_bolt_not_paid }}</td>
                                            <td>{{ fee_bolt_paid }}</td>
                                            <td>{{ fee_bolt_sum }}</td>   
                            </tr> 
                            <tr>
                                <td>D) Przejazdy gotówkowe (przyjęta gotówka)</td>
                                            <td>{{ cash_bolt_not_paid }}</td>
                                            <td>{{ cash_bolt_paid }}</td>
                                            <td>{{ cash_bolt_sum }}</td>   
                            </tr> 
                            <tr>
                                <td>E) Rekompensaty</td>
                                            <td>{{ recomendation_bolt_not_paid }}</td>
                                            <td>{{ recomendation_bolt_paid }}</td>
                                            <td>{{ recomendation_bolt_sum }}</td>  
                            </tr> 
                            <tr>
                                <td>F) Bonus</td>
                                            <td>{{ bonus_bolt_not_paid }}</td>
                                            <td>{{ bonus_bolt_paid }}</td>
                                            <td>{{ bonus_bolt_sum }}</td>  
                            </tr>  
                            <tr>
                                <td>G) Zwroty</td>
                                            <td>{{ returns_bolt_not_paid }}</td>
                                            <td>{{ returns_bolt_paid }}</td>
                                            <td>{{ returns_bolt_sum }}</td>  
                            </tr>  
                            <tr>
                                <td>H) Napiwek</td>
                                      <td>{{ tip_bolt_not_paid }}</td>
                                            <td>{{ tip_bolt_paid }}</td>
                                            <td>{{ tip_bolt_sum }}</td> 
                            </tr>  
                            <tr>
                                <td>I) Tygodniowy bilans</td>                                
                                      <td>{{ balance_bolt_not_paid }}</td>
                                            <td>{{ balance_bolt_paid }}</td>
                                            <td>{{ balance_bolt_sum }}</td> 
                            </tr>   
                                        <tr>
                                            <td>Suma</td>
                                            <td>{{ sum_bolt_not_paid }}</td>
                                            <td>{{ sum_bolt_paid }}</td>
                                            <td>{{ sum_bolt_sum }}</td>
                                        </tr>                                                                                                                                                                                                                                                                                                                                     
                    </tbody>

                        <thead>
                            <tr>
                                <th><h2>Uber Taxi</h2></th>                        
                                       <th>NieRozliczone</th>                        
                                        <th>rozliczone</th>                        
                                        <th>Suma</th>                                                     
                            </tr>
                        </thead>                    
                        <tbody>
                                <tr>
                                    <td>A) Wypłacono Ci</td>
                                      <td>{{ total_uber_not_paid }}</td>
                                            <td>{{ total_uber_paid }}</td>
                                            <td>{{ total_uber_sum }}</td> 
                                </tr>
                                <tr>
                                    <td>B) Wypłacono Ci : Twój przychód</td>
                                      <td>{{ paid_uber_not_paid }}</td>
                                            <td>{{ paid_uber_paid }}</td>
                                            <td>{{ paid_uber_sum }}</td> 
                                </tr>  
                                <tr>
                                    <td>C) Wypłacono Ci : Bilans przejazdu : Wypłaty : Odebrana gotówka</td>
                                          <td>{{ balance_uber_not_paid }}</td>
                                                <td>{{ balance_uber_paid }}</td>
                                                <td>{{ balance_uber_sum }}</td> 
                                </tr> 
                                <tr>
                                    <td>D) Wypłacono Ci:Twój przychód:Opłata:Czas oczekiwania w miejscu odbioru</td>
                                              <td>{{ waiting_uber_not_paid }}</td>
                                                    <td>{{ waiting_uber_paid }}</td>
                                                    <td>{{ waiting_uber_sum }}</td> 
                                </tr> 
                                <tr>
                                    <td>E) Wypłacono Ci:Twój przychód:Napiwek</td>
                                              <td>{{ tip_uber_not_paid }}</td>
                                                    <td>{{ tip_uber_paid }}</td>
                                                    <td>{{ tip_uber_sum }}</td> 
                                </tr> 
                                <tr>
                                    <td>F) Wypłacono Ci:Twój przychód:Promocja:Nagroda za polecenie</td>
                                              <td>{{ recomendation_uber_not_paid }}</td>
                                                    <td>{{ recomendation_uber_paid }}</td>
                                                    <td>{{ recomendation_uber_sum }}</td> 
                                </tr>  
                                <tr>
                                    <td>G) Wypłacono Ci:Twój przychód:Promocja:Program Quest</td>
                                                  <td>{{ quest_uber_not_paid }}</td>
                                                        <td>{{ quest_uber_paid }}</td>
                                                        <td>{{ quest_uber_sum }}</td> 
                                </tr>  
                                <tr>
                                    <td>H) Wypłacono Ci:Twój przychód:Opłata:Odwołanie</td>
                                                  <td>{{ cancel_uber_not_paid }}</td>
                                                        <td>{{ cancel_uber_paid }}</td>
                                                        <td>{{ cancel_uber_sum }}</td> 
                                </tr> 
                                        <tr>
                                            <td>Suma</td>
                                                  <td>{{ sum_uber_not_paid }}</td>
                                                        <td>{{ sum_uber_paid }}</td>
                                                        <td>{{ sum_uber_sum }}</td> 
                                        </tr>                                                                                                                                                                                                                                                                                                                                              
                        </tbody>                    

                            <thead>
                                <tr>
                                    <th><h2>Uber Eats</h2></th>                        
                                        <th>NieRozliczone</th>                        
                                        <th>rozliczone</th>                        
                                        <th>Suma</th>                                                          
                                </tr>
                            </thead>                    
                            <tbody>
                                                                    <tr>
                                        <td>A) Wypłacono Ci</td>
                                          <td>{{ total_uber_eats_not_paid }}</td>
                                                <td>{{ total_uber_eats_paid }}</td>
                                                <td>{{ total_uber_eats_sum }}</td> 
                                    </tr>
                                    <tr>
                                        <td>B) Wypłacono Ci : Twój przychód</td>
                                          <td>{{ paid_uber_eats_not_paid }}</td>
                                                <td>{{ paid_uber_eats_paid }}</td>
                                                <td>{{ paid_uber_eats_sum }}</td> 
                                    </tr>  
                                    <tr>
                                        <td>C) Wypłacono Ci : Bilans przejazdu : Wypłaty : Odebrana gotówka</td>
                                              <td>{{ balance_uber_eats_not_paid }}</td>
                                                    <td>{{ balance_uber_eats_paid }}</td>
                                                    <td>{{ balance_uber_eats_sum }}</td> 
                                    </tr> 
                                    <tr>
                                        <td>D) Wypłacono Ci:Twój przychód:Opłata:Czas oczekiwania w miejscu odbioru</td>
                                                  <td>{{ waiting_uber_eats_not_paid }}</td>
                                                        <td>{{ waiting_uber_eats_paid }}</td>
                                                        <td>{{ waiting_uber_eats_sum }}</td> 
                                    </tr> 
                                    <tr>
                                        <td>E) Wypłacono Ci:Twój przychód:Napiwek</td>
                                                  <td>{{ tip_uber_eats_not_paid }}</td>
                                                        <td>{{ tip_uber_eats_paid }}</td>
                                                        <td>{{ tip_uber_eats_sum }}</td> 
                                    </tr> 
                                    <tr>
                                        <td>F) Wypłacono Ci:Twój przychód:Promocja:Nagroda za polecenie</td>
                                                  <td>{{ recomendation_uber_eats_not_paid }}</td>
                                                        <td>{{ recomendation_uber_eats_paid }}</td>
                                                        <td>{{ recomendation_uber_eats_sum }}</td> 
                                    </tr>  
                                    <tr>
                                        <td>G) Wypłacono Ci:Twój przychód:Promocja:Program Quest</td>
                                                      <td>{{ quest_uber_eats_not_paid }}</td>
                                                            <td>{{ quest_uber_eats_paid }}</td>
                                                            <td>{{ quest_uber_eats_sum }}</td> 
                                    </tr>  
                                    <tr>
                                        <td>H) Wypłacono Ci:Twój przychód:Opłata:Odwołanie</td>
                                                      <td>{{ cancel_uber_eats_not_paid }}</td>
                                                            <td>{{ cancel_uber_eats_paid }}</td>
                                                            <td>{{ cancel_uber_eats_sum }}</td> 
                                    </tr>     
                                    <tr>
                                        <td>Suma</td>
                                                      <td>{{ sum_uber_eats_not_paid }}</td>
                                                            <td>{{ sum_uber_eats_paid }}</td>
                                                            <td>{{ sum_uber_eats_sum }}</td> 
                                    </tr>                                                                                                                                                                                                                                                                                                                                                    
                            </tbody>                    
                    <thead>
                        <tr>
                            <th><h2>Deligo</h2></th>                        
                                         <th>NieRozliczone</th>                        
                                        <th>rozliczone</th>                        
                                        <th>Suma</th>                       
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Suma Netto</td>
                            <td>{{ deligo_netto_not_paid }}</td>
                            <td>{{ deligo_netto_paid }}</td>
                            <td>{{ deligo_netto_sum }}</td>
                        </tr>
                        <tr>
                            <td>Suma Brutto</td>
                            <td>{{ deligo_brutto_not_paid }}</td>
                            <td>{{ deligo_brutto_paid }}</td>
                            <td>{{ deligo_brutto_sum }}</td>
                        </tr>  
                                            <tr>
                                                <td>Suma</td>
                                                <td>{{ deligo_sum_not_paid }}</td>
                                                <td>{{ deligo_sum_paid }}</td>
                                                <td>{{ deligo_sum_sum }}</td>
                                            </tr>                                       
                    </tbody>
                        <thead>
                            <tr>
                                <th><h2>Pozostałe</h2></th>
                                    <th>NieRozliczone</th>                        
                                    <th>rozliczone</th>                        
                                    <th>Suma</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>DKV</td>
                                <td>{{ dkv_not_paid }}</td>
                                <td>{{ dkv_paid }}</td>
                                <td>{{ dkv_sum }}</td>
                            </tr>
                            <tr>
                                <td>Wynajem</td>
                                    <td>{{ rent_not_paid }}</td>
                                    <td>{{ rent_paid }}</td>
                                    <td>{{ rent_sum }}</td>
                            </tr> 
                            <tr>
                                <td>Premie</td>
                                        <td>{{ bonus_not_paid }}</td>
                                        <td>{{ bonus_paid }}</td>
                                        <td>{{ bonus_sum }}</td>
                            </tr>
                            <tr>
                                <td>Obciążenia</td>
                                        <td>{{ punish_not_paid }}</td>
                                        <td>{{ punish_paid }}</td>
                                        <td>{{ punish_sum }}</td>
                            </tr> 
                            <tr>
                                <td>Zwrot VAT</td>
                                        <td>{{ vat_not_paid }}</td>
                                        <td>{{ vat_paid }}</td>
                                        <td>{{ vat_sum }}</td>
                            </tr>   
                                    <tr>
                                        <td>Suma</td>
                                            <td>{{ total_not_paid }}</td>
                                            <td>{{ total_paid }}</td>
                                            <td>{{ total_sum }}</td>
                                    </tr>                                                                                
                        </tbody>
                    </v-simple-table>              
        </v-card-text>
    </v-card>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'

export default {
    name: "ImportPreview",
    data: () => ({
        deligo_netto_not_paid:0,
        deligo_netto_paid:0,
        deligo_netto_sum:0,
        deligo_brutto_not_paid:0,
        deligo_brutto_paid:0,
        deligo_brutto_sum:0,
        deligo_sum_not_paid:0,
        deligo_sum_paid:0,
        deligo_sum_sum:0,
        total_freenow_not_paid:0,
        total_freenow_paid:0,
        total_freenow_sum:0,
        cash_freenow_not_paid:0,
        cash_freenow_paid:0,
        cash_freenow_sum:0,
        bonus_freenow_not_paid:0,
        bonus_freenow_paid:0,
        bonus_freenow_sum:0,
        tip_freenow_not_paid:0,
        tip_freenow_paid:0,
        tip_freenow_sum:0,
        tours_freenow_not_paid: 0,
        tours_freenow_paid: 0,
        tours_freenow_sum: 0,  
        sum_freenow_not_paid:0,
        sum_freenow_paid:0,
        sum_freenow_sum:0,    
        brutto_bolt_not_paid:0,
        brutto_bolt_paid:0,
        brutto_bolt_sum:0,
        cancel_bolt_not_paid:0,
        cancel_bolt_paid:0,
        cancel_bolt_sum:0,
        fee_bolt_not_paid:0,
        fee_bolt_paid:0,
        fee_bolt_sum:0,
        cash_bolt_not_paid:0,
        cash_bolt_paid:0,
        cash_bolt_sum:0,
        recomendation_bolt_not_paid:0,
        recomendation_bolt_paid:0,
        recomendation_bolt_sum:0,
        bonus_bolt_not_paid:0,
        bonus_bolt_paid:0,
        bonus_bolt_sum:0,
        returns_bolt_not_paid:0,
        returns_bolt_paid:0,
        returns_bolt_sum:0,
        tip_bolt_not_paid:0,
        tip_bolt_paid:0,
        tip_bolt_sum:0,
        balance_bolt_not_paid:0,
        balance_bolt_paid:0,
        balance_bolt_sum:0,
        sum_bolt_not_paid:0,
        sum_bolt_paid:0,
        sum_bolt_sum:0,
        total_uber_not_paid:0,
        total_uber_paid:0,
        total_uber_sum:0,
        paid_uber_not_paid:0,
        paid_uber_paid:0,
        paid_uber_sum:0,
        balance_uber_not_paid:0,
        balance_uber_paid:0,
        balance_uber_sum:0,
        waiting_uber_not_paid:0,
        waiting_uber_paid:0,
        waiting_uber_sum:0,
        tip_uber_not_paid:0,
        tip_uber_paid:0,
        tip_uber_sum:0,
        recomendation_uber_not_paid:0,
        recomendation_uber_paid:0,
        recomendation_uber_sum:0,
        quest_uber_not_paid:0,
        quest_uber_paid:0,
        quest_uber_sum:0,
        cancel_uber_not_paid:0,
        cancel_uber_paid:0,
        cancel_uber_sum:0,
        sum_uber_not_paid:0,
        sum_uber_paid:0,
        sum_uber_sum:0,
        total_uber_eats_not_paid:0,
        total_uber_eats_paid:0,
        total_uber_eats_sum:0,
        paid_uber_eats_not_paid:0,
        paid_uber_eats_paid:0,
        paid_uber_eats_sum:0,
        balance_uber_eats_not_paid:0,
        balance_uber_eats_paid:0,
        balance_uber_eats_sum:0,
        waiting_uber_eats_not_paid:0,
        waiting_uber_eats_paid:0,
        waiting_uber_eats_sum:0,
        tip_uber_eats_not_paid:0,
        tip_uber_eats_paid:0,
        tip_uber_eats_sum:0,
        recomendation_uber_eats_not_paid:0,
        recomendation_uber_eats_paid:0,
        recomendation_uber_eats_sum:0,
        quest_uber_eats_not_paid:0,
        quest_uber_eats_paid:0,
        quest_uber_eats_sum:0,
        cancel_uber_eats_not_paid:0,
        cancel_uber_eats_paid:0,
        cancel_uber_eats_sum:0,
        sum_uber_eats_not_paid:0,
        sum_uber_eats_paid:0,
        sum_uber_eats_sum:0,
        dkv_not_paid:0,
        dkv_paid:0,
        dkv_sum:0,
        rent_not_paid:0,
        rent_paid:0,
        rent_sum:0,
        bonus_not_paid:0,
        bonus_paid:0,
        bonus_sum:0,
        punish_not_paid:0,
        punish_paid:0,
        punish_sum:0,
        vat_not_paid:0,
        vat_paid:0,
        vat_sum:0,
        total_not_paid:0,
        total_paid:0,
        total_sum:0,            
        drivers: [],
        driver: null,
        weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week() - 1),

    }),
    async created() {
        await this.getDriversSelect()
    },
    methods:{
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },         
        async getDriversSelect() {
            await this.toggleLoading(true);
            try {
                let drivers = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/drivers?itemsPerPage=10000&sort=lastName&direction=" + false,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                drivers.data.data.items.unshift({
                    id: null,
                    driverName: 'Brak Kierowcy'
                })
                drivers.data.data.items.forEach((item, i) => {
                    if (i > 0) {
                        item.driverName = item.firstName + ' ' + item.lastName
                    }
                })
                this.drivers = drivers.data.data.items
            } catch (e) {
                console.log(e);
                this.dialog = false;
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        },  
        async getDashboardForDriver(){
            await this.toggleLoading(true);
            try{
                let data = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/settlements-new/get-dashboard-for-user-admin/"+this.weekNo+'/'+this.driver,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                console.log(data.data.data)
                    this.deligo_netto_not_paid= data.data.data.deligoPaidSettlement[0].netto/100 || 0
                    this.deligo_netto_paid= data.data.data.deligoPaidSettlement[0].netto / 100 || 0
                    this.deligo_netto_sum= (parseFloat(this.deligo_netto_not_paid) + parseFloat(this.deligo_netto_paid)).toFixed(2)
                    this.deligo_brutto_not_paid= data.data.data.deligoPaidSettlement[0].brutto / 100 || 0
                    this.deligo_brutto_paid= data.data.data.deligoPaidSettlement[0].brutto / 100 || 0
                    this.deligo_brutto_sum= (parseFloat(this.deligo_brutto_not_paid) + parseFloat(this.deligo_brutto_paid)).toFixed(2)
                    this.deligo_sum_not_paid=data.data.data.deligoNotPaidSettlement[0].totalDeligo||0
                    this.deligo_sum_paid=data.data.data.deligoPaidSettlement[0].totalDeligo || 0
                    this.deligo_sum_sum= (parseFloat(this.deligo_sum_not_paid) + parseFloat(this.deligo_sum_paid)).toFixed(2)

                    this.total_freenow_not_paid= data.data.data.freeNowSettlementsNotPaid[0].totalFreeNow / 100 || 0
                    this.total_freenow_paid= data.data.data.freeNowSettlementsPaid[0].totalFreeNow / 100 || 0
                    this.total_freenow_sum= (parseFloat(this.total_freenow_not_paid) + parseFloat(this.total_freenow_paid)).toFixed(2)
                    this.cash_freenow_not_paid= data.data.data.freeNowSettlementsNotPaid[0].cash/100 || 0
                    this.cash_freenow_paid= data.data.data.freeNowSettlementsPaid[0].cash / 100 || 0
                    this.cash_freenow_sum= (parseFloat(this.cash_freenow_not_paid) + parseFloat(this.cash_freenow_paid)).toFixed(2)
                    this.bonus_freenow_not_paid= data.data.data.freeNowSettlementsNotPaid[0].quest / 100 || 0
                    this.bonus_freenow_paid= data.data.data.freeNowSettlementsPaid[0].quest / 100 || 0
                    this.bonus_freenow_sum= (parseFloat(this.bonus_freenow_not_paid) + parseFloat(this.bonus_freenow_paid)).toFixed(2)
                    this.tip_freenow_not_paid= data.data.data.freeNowSettlementsNotPaid[0].tip / 100 || 0
                    this.tip_freenow_paid= data.data.data.freeNowSettlementsPaid[0].tip / 100 || 0
                    this.tip_freenow_sum= (parseFloat(this.tip_freenow_not_paid) + parseFloat(this.tip_freenow_paid)).toFixed(2)
                    this.tours_freenow_not_paid= data.data.data.freeNowSettlementsNotPaid[0].drives || 0
                    this.tours_freenow_paid= data.data.data.freeNowSettlementsPaid[0].drives || 0
                    this.tours_freenow_sum= (parseFloat(this.tours_freenow_not_paid) + parseFloat(this.tours_freenow_paid)).toFixed(2)
                    this.sum_freenow_not_paid=data.data.data.freeNowSettlementsNotPaid[0].totalFreeNowAll||0
                    this.sum_freenow_paid= data.data.data.freeNowSettlementsPaid[0].totalFreeNowAll || 0
                    this.sum_freenow_sum= (parseFloat(this.sum_freenow_not_paid)+ parseFloat(this.sum_freenow_paid)).toFixed(2)

                    this.brutto_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].brutto / 100 || 0
                    this.brutto_bolt_paid= data.data.data.boltSettlementPaid[0].brutto / 100 || 0
                    this.brutto_bolt_sum= (parseFloat(this.brutto_bolt_not_paid) + parseFloat(this.brutto_bolt_paid)).toFixed(2)
                    this.cancel_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].cancelFee / 100 || 0
                    this.cancel_bolt_paid= data.data.data.boltSettlementPaid[0].cancelFee / 100 || 0
                    this.cancel_bolt_sum= (parseFloat(this.cancel_bolt_not_paid) + parseFloat(this.cancel_bolt_paid)).toFixed(2)
                    this.fee_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].boltFee / 100 || 0
                    this.fee_bolt_paid= data.data.data.boltSettlementPaid[0].boltFee / 100 || 0
                    this.fee_bolt_sum= (parseFloat(this.fee_bolt_not_paid) + parseFloat(this.fee_bolt_paid)).toFixed(2)
                    this.cash_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].cashFee / 100 || 0
                    this.cash_bolt_paid= data.data.data.boltSettlementPaid[0].cashFee / 100 || 0
                    this.cash_bolt_sum= (parseFloat(this.cash_bolt_not_paid) + parseFloat(this.cash_bolt_paid)).toFixed(2)
                    this.recomendation_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].recomendationFee / 100 || 0
                    this.recomendation_bolt_paid= data.data.data.boltSettlementPaid[0].recomendationFee / 100 || 0
                    this.recomendation_bolt_sum= (parseFloat(this.recomendation_bolt_not_paid) + parseFloat(this.recomendation_bolt_paid)).toFixed(2)
                    this.bonus_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].bonusFee / 100 || 0
                    this.bonus_bolt_paid= data.data.data.boltSettlementPaid[0].bonusFee / 100 || 0
                    this.bonus_bolt_sum= (parseFloat(this.bonus_bolt_not_paid) + parseFloat(this.bonus_bolt_paid)).toFixed(2)
                    this.returns_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].returnsFee / 100 || 0
                    this.returns_bolt_paid= data.data.data.boltSettlementPaid[0].returnsFee / 100 || 0
                    this.returns_bolt_sum= (parseFloat(this.returns_bolt_not_paid) + parseFloat(this.returns_bolt_paid)).toFixed(2)
                    this.tip_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].tipFee / 100 || 0
                    this.tip_bolt_paid= data.data.data.boltSettlementPaid[0].tipFee / 100 || 0
                    this.tip_bolt_sum= (parseFloat(this.tip_bolt_not_paid) + parseFloat(this.tip_bolt_paid)).toFixed(2)
                    this.balance_bolt_not_paid= data.data.data.boltSettlementNotPaid[0].total / 100 || 0
                    this.balance_bolt_paid= data.data.data.boltSettlementPaid[0].total / 100 || 0
                    this.balance_bolt_sum= (parseFloat(this.balance_bolt_not_paid) + parseFloat(this.balance_bolt_paid)).toFixed(2)
                    this.sum_bolt_not_paid=data.data.data.boltSettlementNotPaid[0].totalBolt ||0
                    this.sum_bolt_paid=data.data.data.boltSettlementPaid[0].totalBolt || 0
                    this.sum_bolt_sum= (parseFloat(this.sum_bolt_not_paid)+ parseFloat(this.sum_bolt_paid)).toFixed(2)

                    this.total_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].totalWithoutCash / 100 ||0
                    this.total_uber_paid=data.data.data.uberSettlementsPaid[0].totalWithoutCash / 100 ||0
                    this.total_uber_sum= (parseFloat(this.sum_uber_not_paid)+ parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.paid_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].totalWithCash / 100 ||0
                    this.paid_uber_paid=data.data.data.uberSettlementsPaid[0].totalWithCash / 100 ||0
                    this.paid_uber_sum= (parseFloat(this.sum_uber_not_paid)+ parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.balance_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].cashFee / 100 ||0
                    this.balance_uber_paid=data.data.data.uberSettlementsPaid[0].cashFee / 100 ||0
                    this.balance_uber_sum= (parseFloat(this.sum_uber_not_paid)+ parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.waiting_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].waitingFee / 100 ||0
                    this.waiting_uber_paid=data.data.data.uberSettlementsPaid[0].waitingFee / 100 ||0
                    this.waiting_uber_sum= (parseFloat(this.sum_uber_not_paid)+ parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.tip_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].tipFee / 100 ||0
                    this.tip_uber_paid=data.data.data.uberSettlementsPaid[0].tipFee / 100 ||0
                    this.tip_uber_sum= (parseFloat(this.sum_uber_not_paid) + parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.recomendation_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].recomendationFee / 100 ||0
                    this.recomendation_uber_paid=data.data.data.uberSettlementsPaid[0].recomendationFee / 100 ||0
                    this.recomendation_uber_sum= (parseFloat(this.sum_uber_not_paid) + parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.quest_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].quest / 100 ||0
                    this.quest_uber_paid=data.data.data.uberSettlementsPaid[0].quest / 100 ||0
                    this.quest_uber_sum= (parseFloat(this.sum_uber_not_paid) + parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.cancel_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].cancelFee / 100 ||0
                    this.cancel_uber_paid=data.data.data.uberSettlementsPaid[0].cancelFee / 100 ||0
                    this.cancel_uber_sum= (parseFloat(this.sum_uber_not_paid) + parseFloat(this.sum_uber_sum)).toFixed(2)
                    this.sum_uber_not_paid=data.data.data.uberSettlementsNotPaid[0].totalUber ||0
                    this.sum_uber_paid=data.data.data.uberSettlementsPaid[0].totalUber ||0
                    this.sum_uber_sum= (parseFloat(this.sum_uber_not_paid)+ parseFloat(this.sum_uber_sum)).toFixed(2)

                this.total_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].totalWithoutCash / 100 || 0
                this.total_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].totalWithoutCash / 100 || 0
                this.total_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.paid_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].totalWithCash / 100 || 0
                this.paid_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].totalWithCash / 100 || 0
                this.paid_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.balance_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].cashFee / 100 || 0
                this.balance_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].cashFee / 100 || 0
                this.balance_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.waiting_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].waitingFee / 100 || 0
                this.waiting_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].waitingFee / 100 || 0
                this.waiting_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.tip_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].tipFee / 100 || 0
                this.tip_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].tipFee / 100 || 0
                this.tip_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.recomendation_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].recomendationFee / 100 || 0
                this.recomendation_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].recomendationFee / 100 || 0
                this.recomendation_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.quest_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].quest / 100 || 0
                this.quest_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].quest / 100 || 0
                this.quest_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.cancel_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].cancelFee / 100 || 0
                this.cancel_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].cancelFee / 100 || 0
                this.cancel_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)
                this.sum_uber_eats_not_paid = data.data.data.uberEatsSettlementsNotPaid[0].totalUberEats  || 0
                this.sum_uber_eats_paid = data.data.data.uberEatsSettlementsPaid[0].totalUberEats || 0
                this.sum_uber_eats_sum = (parseFloat(this.sum_uber_eats_not_paid) + parseFloat(this.sum_uber_eats_sum)).toFixed(2)

                    this.dkv_not_paid=data.data.data.dkvSettlementsNotPaid[0].totalDkvBalance || 0
                    this.dkv_paid= data.data.data.dkvSettlementsPaid[0].totalDkvBalance || 0
                    this.dkv_sum=(parseFloat(this.dkv_not_paid)+parseFloat(this.dkv_paid)).toFixed(2)
                    this.rent_not_paid=data.data.data.rentSettlementsNotPaid[0].rentPrice || 0
                    this.rent_paid= data.data.data.rentSettlementsPaid[0].rentPrice || 0
                    this.rent_sum= (parseFloat(this.rent_not_paid) + parseFloat(this.rent_paid)).toFixed(2)
                    this.bonus_not_paid = data.data.data.bonusSettlementsPositiveNotPaid[0].bonusBalanceTotal
                    this.bonus_paid= data.data.data.bonusSettlementsPositivePaid[0].bonusBalanceTotal || 0
                    this.bonus_sum= (parseFloat(this.bonus_not_paid) + parseFloat(this.bonus_paid)).toFixed(2)
                    this.punish_not_paid=data.data.data.bonusSettlementsNegativeNotPaid[0].bonusBalanceTotal || 0
                    this.punish_paid= data.data.data.bonusSettlementsNegativePaid[0].bonusBalanceTotal || 0
                    this.punish_sum= (parseFloat(this.punish_not_paid) + parseFloat(this.punish_paid)).toFixed(2)
                    this.vat_not_paid=data.data.data.invoicesSettlementsNotPaid[0].totalInvoiceReturn || 0
                    this.vat_paid= data.data.data.invoicesSettlementsPaid[0].totalInvoiceReturn||0
                    this.vat_sum= (parseFloat(this.vat_not_paid) + parseFloat(this.vat_paid)).toFixed(2)
                    this.total_not_paid= (parseFloat(this.dkv_not_paid)+ parseFloat(this.rent_not_paid) + parseFloat(this.bonus_not_paid)+ parseFloat(this.punish_not_paid)+ parseFloat(this.vat_not_paid)).toFixed(2)
                    this.total_paid= (parseFloat(this.dkv_paid) + parseFloat(this.rent_paid) + parseFloat(this.bonus_paid) + parseFloat(this.punish_paid) + parseFloat(this.vat_paid)).toFixed(2)
                    this.total_sum= (parseFloat(this.dkv_sum) + parseFloat(this.rent_sum) + parseFloat(this.bonus_sum) + parseFloat(this.punish_sum) + parseFloat(this.vat_sum)).toFixed(2)
            }catch(e){
                console.log(e);
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        }      
    }
}
</script>