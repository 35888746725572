<template>
  <v-card>
    <DriverDetailsVue ref="detailsDriver" :showFull="true" :selectedDriver="currentDriver"></DriverDetailsVue>
    <v-card-title>
      Rozliczenia
      <v-spacer></v-spacer>
      <v-text-field label="Tydzień" v-model="weekNo" type="week"
        @change="loadSettlements()"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-select @change="loadSettlements()" v-model="settlementType" :items="settlementTypes" item-text="text" item-value="id" label="Rodzaj Rozliczenia"></v-select>
      <v-spacer></v-spacer>
        <v-select  @change="loadSettlements()" label="Oddział"  v-model="departament" :items="departaments" item-text="departamentName" item-value="id"></v-select>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Szukaj"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="settlements"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      :search="search"
      class="elevation-1"
    >
          <template v-slot:item.editBtn="{ item }">
            <v-btn class="warning" @click="editDriver(item.driver_id)">
              <v-icon small class="pt-1">mdi-pencil</v-icon>
            </v-btn>
          </template>
  </v-data-table>    
      <v-footer class="justify-center">
        <v-btn class="primary" @click="createSettlement()">
          Generuj Wypłaty
        </v-btn>
        <v-btn class="primary" @click="downloadXLSX()">
          Pobierz XLSX
        </v-btn>
      </v-footer>
  </v-card>   
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import * as reader from 'xlsx'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import DriverDetailsVue from './DriverDetails.vue';
dayjs.extend(weekOfYear)
export default {
  name: "Settlements",
  components:  {DriverDetailsVue},
  data: () => ({
        currentDriver:null,
        search: '',
        options:{
          sortDesc:'true'
        },
        totalRecords:0,
        loading:false,
        departaments:[],
        departament:'',
        headers: [
          {
            text: 'Kierowca',
            align: 'start',          
            value: 'driverName',
          },
          { text: "", value: "editBtn", sortable: false },
          {
            text: 'Rodzaj Rozliczenia',
            align: 'start',
            value: 'settlementType',
            sortable:false,
          },          
          {
            text: 'Łącznie Bolt',
            align: 'start',
            value: 'totalBoltNew',
          },  
          {
            text: 'Łącznie Uber',
            align: 'start',
            value: 'totalUberNew',
          },                       
          {
            text: 'Łącznie FreeNow',
            align: 'start',
            value: 'totalFreeNowNew',
          }, 
          {
            text: 'Łącznie Uber Eats',
            align: 'start',
            value: 'totalUberEatsNew',
          },  
          {
            text: 'Łącznie Deligo',
            align: 'start',
            value: 'totalDeligoNew',
          },                    
          {
            text: 'Łącznie Premie i Obciążenia',
            align: 'start',
            value: 'bonusBalanceTotal',
          },           
          {
            text: 'Łącznie Zwrot VAT',
            align: 'start',
            value: 'totalInvoiceReturn',
          },
          {
            text: 'Łącznie DKV',
            align: 'start',
            value: 'totalDkvBalance',
          }, 
          {
            text: 'Wynajem',
            align: 'start',
            value: 'rentPrice',
          },          
          {
            text: 'Gotówka',
            align: 'start',
            value: 'totalCash',
            sortable: false
          },                                                                                                   
          { text: 'Koszt Adm.', value: 'adminTax', sortable: false }, 
          { text: 'Zus', value: 'zus', sortable: false }, 
          { text: 'Koszt Rozliczenia', value: 'totalCost', sortable: false }, 
          { text: 'Bieżący Tydzień', value: 'totalNew',sortable:false }, 
          { text: 'Poprzedni Tydzień', value: 'totalPreviousWeek', sortable: false },          
          { text: 'Suma', value: 'totalSettlement', sortable: false }, 
        ],
    settlements: [],
    weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week()-1),
    settlementType: 'week',
    settlementTypes: [{
      id: 'week',
      text: 'Tygodniowe'
    },
    {
      id: 'two_week',
      text: '2 Tygodnie'
    },
    {
      id: 'month',
      text: 'Miesięczne'
    }
    ],    
  }),
  watch: {
    options: {
      async handler() {
        if(!this.departament){
          await this.getDepartamentSelect()
        }        
        await this.loadSettlements()
      },
      deep: true,
    },
    async search() {
      this.options.page = 1 
      await this.loadSettlements()
    }
  },
  async beforeMount() {
    
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async editDriver(id){
      this.currentDriver = id
      this.$refs.detailsDriver.getDriverById(id)
    },
    async downloadXLSX(){
      await this.toggleLoading(true);
      try{
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const response = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements-new/list-payouts-xlsx/${this.weekNo}/${this.settlementType}/${this.departament}?sort=${sortBy.length ? sortBy : 'driverName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });        
        const data = response.data.data.map((item) => {          
          return {
            driverName: item.driverName,
            coopType: item.coopType,
            totalBolt: item.totalBolt,
            totalUber: item.totalUber,
            totalFreeNow: item.totalFreeNow,
            totalUberEats: item.totalUberEats,
            totalDeligo: item.totalDeligo,
            totalDkvBalance: item.totalDkvBalance,
            totalInvoiceReturn: item.totalInvoiceReturn,
            administrationTax: item.adminTax,
            zus: item.zus,
            cost: item.totalCost,
            total: item.totalSettlement,
          }
        })
        const worksheet = reader.utils.json_to_sheet(data);
        const workbook = reader.utils.book_new();
        reader.utils.book_append_sheet(workbook, worksheet, "Raport");
        reader.utils.sheet_add_aoa(worksheet, [["Kierowca", "Rodzaj Współpracy", "Bolt", "Uber", "FreeNow", "Uber Eats", "Deligo", "DKV", "Zwrot VAT","ZUS", "Koszt Rozliczenia", "Koszt Administracyjno-Księgowy", "Łącznie"]], { origin: "A1" });
        reader.writeFile(workbook, dayjs().unix() + ".xlsx", { compression: true }); 
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/departaments/list-departaments-for-user/${localStorage.getItem('userid')}?itemsPerPage=100&sort=departamentName&direction=${false}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dep.data.data.items.push({
          id: null,
          departamentName: "Wszystkie"
        })
        this.departament = null
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },    
    async loadSettlements() {      
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const settlements = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements-new/list-payouts/${this.weekNo}/${this.settlementType}/${this.departament}?sort=${sortBy.length ? sortBy : 'driverName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });                  
        settlements.data.data.items.forEach((item)=>{     
          // item.totalThisWeek = ( item.totalPreviousWeek + item.totalNew).toFixed(2)         
          if (item.coopType == 'connection'){
            item.settlementType = 'Podpięcie'
          }else if(item.coopType == 'mob'){
            item.settlementType = '50/50'
          }else if(item.coopType == 'b2b_no_vat'){
            item.settlementType = 'B2B Bez Vat'
          } else if (item.coopType == 'b2b_vat') {
            item.settlementType = 'B2B Z Vat'
          } else if (item.coopType == 'rent') {
            item.settlementType = 'Wynajmujący'
          }
          if(item.rentPriceSecond){
            item.rentPrice = 0
          }
        })              
        this.settlements = settlements.data.data.items
        this.itemsPerPage = settlements.data.data.meta.itemsPerPage
        this.page = settlements.data.data.meta.currentPage
        this.totalRecords = settlements.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },      
    async createSettlement() {     
      // if(this.departament ==null){
      //   Swal.fire("Błąd", "Wbierz oddział", "error");                
      //   return false;
      // }    
      try {
        Swal.fire({
          title: "Utworzyć Rozliczenie?, Może to chwilę potrwać!",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.toggleLoading(true);   
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "/settlements-new/create",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                weekStr: this.weekNo,
                settlementType: this.settlementType,
                departament: this.departament
              }
            });
            await this.toggleLoading(false);  
            Swal.fire("Sukces", 'Utworzono Rozliczenia !', "success");
            await this.loadSettlements()
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }         
    }    
  },
}
</script>
