<template>
    <v-card>
        <v-dialog :persistent="true" :loading="dialogCreateLoading" v-model="dialogCreate" fullscreen hide-overlay
            transition="dialog-bottom-transition">
            <v-card>
                <!-- <v-card-title>
                      <span class="text-h5">{{action}} Kierowcy</span>
                    </v-card-title> -->
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogCreate = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{action}} Kierowcy</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-container>
<v-stepper v-model="currentStep"  vertical>
                
                  <v-stepper-step
                      :complete="currentStep > 1"
                      editable
                      step="1"
                  >
                      Podstawowe Dane Kierowcy
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                      :complete="currentStep > 2"
                      step="2"
                      editable
                  >
                      Adres
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="currentStep > 3" step="3" editable @click="currentStep=3">
                      Dane do Rozliczeń
                  </v-stepper-step>
                  <v-stepper-step :complete="currentStep > 4" step="4" editable @click="currentStep=4">
                      Dodatkowe Informacje
                  </v-stepper-step> 
                  <v-stepper-step :complete="currentStep > 5" step="5" editable>
                      Aplikacje Kierowcy
                  </v-stepper-step>   
                  <v-stepper-step :complete="currentStep > 6" step="6" editable>
                      Dane o Komorniku
                  </v-stepper-step> 
                  <v-stepper-step :complete="currentStep > 7" step="7" editable>
                      Podsumowanie
                  </v-stepper-step>                                                                
                

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Imię" v-model="firstName" required placeholder="Imię"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Nazwisko" v-model="lastName" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Nazwisko"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field label="Telefon" v-model="phone" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Telefon"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field label="E-mail" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="email" v-model="email"
                            placeholder="E-mail"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field label="Hasło" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="password" v-model="password"
                            placeholder="Hasło"></v-text-field>
                        </v-col>                                                
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <v-row>
                        <v-col cols="6">
                          <v-checkbox label="Brak Adresu" v-model="noAddress"></v-checkbox>
                        </v-col> 
                        <v-col cols="6">
                          <v-checkbox label="Adres Zweryfikowany" v-model="verifyAddress"></v-checkbox>
                        </v-col>                          
                        <v-col cols="6">
                          <v-text-field label="Adres" v-model="street" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Adres"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Adres 2" v-model="streetExtraInfo" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Adres 2">
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Miasto" v-model="city" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                            placeholder="Miasto">
                          </v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select label="Oddział" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="departament"
                            :items="departaments" item-text="departamentName" item-value="id"></v-select>
                        </v-col>
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content>  
                    <v-stepper-content step="3">
                      <v-row>
                        <v-col cols="2">
                          <v-checkbox label="Konto za Granicą?" v-model="abroadBankAccount"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox label="Wypłaty Gotówką" v-model="cashPayments"></v-checkbox>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field label="Numer Konta" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="accountNo">
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox label="Student" v-model="isStudent"></v-checkbox>
                        </v-col>                        
                        <v-col cols="3">
                          <v-select label="Częstotliwość Wypłat" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="paymentSchedule"
                            :items="paymentsSchedules" item-text="text" item-value="id"></v-select>
                        </v-col>   
                        <v-col cols="3">
                          <v-select label="Rodzaj Współpracy" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="cooperationType"
                            :items="cooperationTypes" item-text="text" item-value="id"></v-select>
                        </v-col>   
                        <v-col cols="3">
                            <v-select label="Rodzaj Zus" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="zusType"
                            :items="zusTypes" item-text="text" item-value="id"></v-select>
                        </v-col> 
                        <v-col cols="12">
                          <h2>Do starych obliczeń - w nowych nieużywane</h2>                                 
                        </v-col>                         
                        <v-col cols="6">
                          <v-checkbox label="Poniżej 26 Roku Życia?" v-model="isUnder26"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox label="Zatrudniony" v-model="isEmployed"></v-checkbox>
                        </v-col>
                                     
                      </v-row> 
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                                                           
                    </v-stepper-content>
                    <v-stepper-content step="4">
                      <v-row>
                      <v-col cols="4">
                          <v-text-field label="Numer Boczny" placeholder="Numer Boczny" v-model="licenceNumber"></v-text-field>
                        </v-col>
                      <v-col cols="4">
                        <v-text-field label="Marka Auta" v-model="carMake">
                        </v-text-field>
                       </v-col>
                      <v-col cols="4">
                        <v-text-field label="Tablica Rejestracyjna Auta" v-model="carPlate">
                        </v-text-field>
                      </v-col>
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content>   
                    <v-stepper-content step="5">
                      <v-row>
                      <v-col cols="4">
                        <v-text-field label="ID Bolt" placeholder="ID Bolt" v-model="boltId"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="ID Uber" placeholder="ID Uber" v-model="uberId"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field label="ID FreeNow" placeholder="ID FreeNow" v-model="freeId"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="ID UberEats" placeholder="ID UberEats" v-model="uberEatsId"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="ID Deligo" placeholder="ID Deligo" v-model="deligoId"></v-text-field>
                      </v-col>
                      </v-row>
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                      
                    </v-stepper-content> 
                     <v-stepper-content step="6">
                      <v-row>              
                          <v-col cols="2">
                            <v-checkbox label="Komornik" v-model="isReposession"></v-checkbox>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field label="Nr. Konta Komornika" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="reposessionAccountNo">
                            </v-text-field>
                          </v-col>
                        <v-col cols="5">
                          <v-text-field label="Tutuł przelewu - komornik" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="reposessionWireTitle">
                          </v-text-field>
                        </v-col>
                          <v-col cols="12">
                            <v-text-field label="Przelew Komornik Linia 1" v-model="reposessionAddressLine1" required
                              placeholder="Przelew Komornik Linia 1"></v-text-field>
                          </v-col>  
                          <v-col cols="12">
                            <v-text-field label="Przelew Komornik Linia 2" v-model="reposessionAddressLine2" required
                              placeholder="Przelew Komornik Linia 2"></v-text-field>
                          </v-col>  
                          <v-col cols="12">
                            <v-text-field label="Przelew Komornik Linia 3" v-model="reposessionAddressLine3" required
                              placeholder="Przelew Komornik Linia 3"></v-text-field>
                          </v-col>  
                          <v-col cols="12">
                              <v-text-field label="Przelew Komornik Linia 4" v-model="reposessionAddressLine4" required
                                placeholder="Przelew Komornik Linia 4"></v-text-field>
                          </v-col>                                                                                            
                      </v-row> 
                      <v-btn
                      color="primary"
                      @click="saveStep(currentStep)"
                      >
                      Dalej
                      </v-btn>

                      <v-btn text  @click="previousStep(currentStep)">
                          Poprzedni Krok
                      </v-btn>                                           
                     </v-stepper-content>   
                     <v-stepper-content step="7">
                  <v-simple-table>
                                          <template v-slot:default>
                                          <thead>
                                              <tr>
                                              <th class="text-left">
                                                  Nazw Pola
                                              </th>
                                              <th class="text-left">
                                                  Wartość
                                              </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr>
                                                  <td>Imię</td>
                                                  <td>{{ firstName.trim().length > 0 ? '✅' +firstName : '❌ Uzupełnij Imię' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Nazwisko</td>
                                                  <td>{{ lastName.trim().length > 0 ? '✅' +lastName : '❌ Uzupełnij Nazwisko' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Email</td>
                                                  <td>{{ email.trim().length > 0 ? '✅' +email : '❌ Uzupełnij Email' }}</td>
                                              </tr>                                                        
                                              <tr>
                                                  <td>Telefon</td>                                                  
                                                  <td>{{ phone.trim().length > 0 ? '✅' +phone : '❌ Uzupełnij Telefon' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Nowe Hasło</td>
                                                  <td>{{ password?.trim().length > 0 ? '✅' +password : 'poprzednie hasło' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Adres</td>                                                  
                                                  <td>{{ street.trim().length > 0 ? '✅' +street : '❌ Uzupełnij Adres' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Adres 2</td>
                                                  <td>{{ streetExtraInfo.trim().length >0 ? '✅' +streetExtraInfo : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>  
                                              <tr>
                                                  <td>Miasto</td>                                                  
                                                  <td>{{ city.trim().length > 0 ? '✅' +city : '❌ Uzupełnij Miasto' }}</td>                                                  
                                              </tr>
                                              <tr>
                                                  <td>Oddział</td>
                                                  <td>{{ (departaments.find(it => it.id === departament))?.departamentName ? '✅' +(departaments.find(it => it.id === departament))?.departamentName : '❌ Nie wybrano' }}</td>
                                              </tr>
                                            <tr>
                                                  <td>Numer Konta</td>                                                  
                                                  <td>{{ !cashPayments ? (accountNo.trim().length == 26 ? '✅' +accountNo : '❌ Niepoprawny numer konta!') : '✅' +'N/D - gotówka' }}</td>                                                  
                                              </tr> 
                                            <tr>
                                                  <td>Konto za Granicą?</td>
                                                  <td>{{ abroadBankAccount ? 'Tak' : 'Nie' }}</td>
                                              </tr>
                                            <tr>
                                                  <td>Rodzaj Współpracy</td>
                                                  <td>{{ (cooperationTypes.find(it => it.id === cooperationType))?.text ? '✅' +(cooperationTypes.find(it => it.id === cooperationType))?.text : '❌ Nie wybrano' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Częstotliwość Rozliczeń</td>
                                                  <td>{{(paymentsSchedules.find(it => it.id === paymentSchedule))?.text ? '✅' + (paymentsSchedules.find(it => it.id === paymentSchedule))?.text: '❌ Nie wybrano' }}</td>
                                              </tr>
                                            <tr>
                                                  <td>Rodzaj Zus</td>
                                                  <td> {{ (zusTypes.find(it => it.id === zusType))?.text ? '✅' + (zusTypes.find(it => it.id === zusType))?.text: '❌ Nie wybrano'}}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Rodzaj Rozliczeń</td>
                                                  <td>{{ cashPayments ?  'Gotówka' : 'Przelew' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Numer Boczny</td>
                                                  <td>{{ licenceNumber.trim().length >0 ? '✅' +licenceNumber : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                                            <tr>
                                                  <td>Marka auta</td>
                                                  <td>{{ carMake.trim().length >0 ? '✅' +carMake : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }} </td>
                                              </tr> 
                                            <tr>
                                                  <td>Numer rejestracyjny</td>
                                                  <td>{{ carPlate.trim().length >0 ? '✅' +carPlate : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                                
                                        <tr>
                                                  <td>Id Bolt</td>                                                  
                                                  <td>{{ boltId.trim().length >0 ? '✅' +boltId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                              
                                            <tr>
                                                  <td>Id Uber</td>                                                  
                                                  <td>{{ uberId.trim().length >0 ? '✅' +uberId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>
    
                                            <tr>
                                                  <td>Id FreeNow</td>                                                  
                                                  <td>{{ freeId.trim().length >0 ? '✅' +freeId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr>                                               
                                            <tr>
                                                  <td>Id UberEats</td>
                                                  <td>{{ uberEatsId.trim().length >0 ? '✅' +uberEatsId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Id Deligo</td>                                                  
                                                  <td>{{ deligoId.trim().length >0 ? '✅' +deligoId : '⚠️ Nieuzupełniona pole - nieobowiązkowe' }}</td>
                                              </tr> 

                                            <tr>
                                                  <td>Komornik?</td>
                                                  <td>{{ isReposession ? 'Tak' : 'Nie' }}</td>
                                              </tr> 
                                            <tr>
                                                  <td>Dane do Przelewu do komornika</td>
                                                  <td>{{ isReposession ? (reposessionAccountNo.trim().length>0 ? '✅' +reposessionAccountNo : '❌ Niepoprawny numer konta!') : '✅' +'N/D - brak komornika' }}</td>
                                              </tr>                                                
                                              <tr>
                                                  <td>Tytuł Przelewu do komornika</td>
                                                  <td>{{ isReposession ? (reposessionWireTitle.trim().length>0 ? '✅' +reposessionWireTitle : '❌ Podaj tytuł!') : '✅' +'N/D - brak komornika' }}</td>
                                              </tr> 
                                              <tr>
                                                  <td>Przelew Komornik Linia 1</td>
                                                  <td>{{isReposession ? (reposessionAddressLine1.trim().length>0 ? '✅' +reposessionAddressLine1 : '❌ Podaj chociaż jedną linię adresu!') : '✅' +'N/D - brak komornika' }}</td>
                                              </tr> 
                                              <tr>
                                                  <td>Przelew Komornik Linia 2</td>
                                                  <td>{{isReposession? reposessionAddressLine2: '✅' +'N/D - brak komornika' }}</td>
                                              </tr> 
                                              <tr>
                                                  <td>Przelew Komornik Linia 3</td>
                                                  <td>{{ isReposession ? reposessionAddressLine3: '✅' +'N/D - brak komornika' }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Przelew Komornik Linia 4</td>
                                                  <td>{{ isReposession ? reposessionAddressLine4: '✅' +'N/D - brak komornika' }}</td>
                                              </tr>                                                                                                                                                                                         
                                                                                                                                                                                                                                                            
                                          </tbody>
                                          </template>                        
                                      </v-simple-table>

                                                          <v-btn
                                      color="primary"
                                      @click="createDriver()"
                                      >
                                      Zapisz
                                      </v-btn>
                                      <v-btn text  @click="previousStep(currentStep)">
                                          Poprzedni Krok
                                      </v-btn>                      
                     </v-stepper-content>                                 
                  </v-stepper-items>
                </v-stepper>                         
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card-title>
            Kierowcy do Akceptacji
            <v-spacer></v-spacer>
        </v-card-title>
    <v-data-table :headers="headers" :items="drivers" :options.sync="options" :server-items-length="totalRecords"
        :loading="loading" :search="search" class="elevation-1">
        <template v-slot:item.actions="{ item }">
            <v-btn class="primary" @click="showEdit(item.id)">
                <v-icon small class="pt-1">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="error" @click="deleteDriver(item.id)">
                <v-icon small class="pt-1">mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    </v-card>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";
import dayjs from 'dayjs';

export default {
    name: "DriverDetails",
    data: () => ({
    noAddress: true,
    verifyAddress: false,         
        currentStep:1,
        zusType: '',
      zusTypes:[
        {
          text: 'Pracownik bez zatrudnienia',
          id: 'no_employed'
        },
        {
          text: 'Pracownik zatrudniony w innej firmie z wynagrodzeniem poniżej najniższej krajowej',
          id: 'employed_below'
        },
        {
          text: 'Pracownik zatrudniony w innej firmie z wynagrodzeniem powyżej lub równej najniższej krajowej',
          id: 'employed_above'
        },
        {
          text: 'Pracownik poniżej 26 roku życia',
          id: 'under_26'
        },                        
      ],        
        isReposession:false,
        reposessionAccountNo:'',
        reposessionWireTitle:'', 
        reposessionAddressLine1:'',
        reposessionAddressLine2: '',
        reposessionAddressLine3: '',
        reposessionAddressLine4: '',   
        carPlate: '',
        carMake: '',         
        search: '',
        password: '',
        street: '',
        dialogCreateLoading: false,
        streetExtraInfo: '',
        city: '',
        licenceNumber: '',
        abroadBankAccount: false,
        cashPayments: false,
        isUnder26: false,
        isStudent: false,
        isEmployed: false,
        accountNo: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        paymentSchedule: '',
        cooperationType: '',
        boltId: '',
        uberId: '',
        uberEatsId: '',
        freeId: '',
        deligoId: '',
        departaments: [],
        name: 'Akceptacja Kierowcy',
        action: 'Akceptacja',
        departament: '',   
        paymentsSchedules: [{
            id: 'week',
            text: 'Tygodniowy'
        },
        {
            id: 'two_week',
            text: '2 Tygodnie'
        }, {
            id: 'month',
            text: 'Miesiąc'
        }],
        cooperationTypes: [{
            id: 'b2b_vat',
            text: 'B2B z VAT'
        },
        {
            id: 'b2b_no_vat',
            text: 'B2B Bez VAT'
        },
        {
            id: 'connection',
            text: 'Podpięcie'
        },
        {
            id: 'mob',
            text: '50/50'
        },
        {
            id: 'rent',
            text: 'Wynajmujący'
        }
        ],              
    dialog: false,
    dialogCreate: false,
    loading: false,
    options: {},
    totalRecords: 0,
    // tbl
    drivers: [],
    num: 0,
    headers: [
        {
            text: 'Imię',
            align: 'start',
            value: 'firstName',
        },
        {
            text: 'Nazwisko',
            align: 'start',
            value: 'lastName',
        },
        {
            text: 'Email',
            align: 'start',
            value: 'email',
        },
        {
            text: 'Telefon',
            align: 'start',
            value: 'phone',
        },
        {
            text: 'Data Dodania',
            align: 'start',
            value: 'created_at',
        },        
        {
            text: 'Jak nas znalazłeś?',
            align: 'start',
            value: 'formEnumValue', 
            sortable: false                       
        },
        { text: "", value: "actions", sortable: false },        
    ],
    }),
    watch: {
        options: {
            async handler() {
                await this.loadDrivers()
            },
            deep: true,
        },
        async search() {
            this.options.page = 1 
            await this.loadDrivers()
        }           
    } ,
    methods:{
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        }, 
        async showEdit(id) {
            this.currentDriverId = id
            this.action = 'Edycja'
            this.dialogCreate = true
            this.currentStep =1
            await this.getDepartamentSelect()
            await this.toggleLoading(true);
            this.dialogCreateLoading = true
            try {
                let driver = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.firstName = driver.data.data.firstName,
                    this.lastName = driver.data.data.lastName,
                    this.isUnder26 = driver.data.data.isUnder26
                this.isStudent = driver.data.data.isStudent
                this.isEmployed = driver.data.data.isEmployed
                this.email = driver.data.data.email,
                    this.phone = driver.data.data.phone,
                    this.departament = driver.data.data.departament,
                    this.boltId = driver.data.data.boltId,
                    this.uberId = driver.data.data.uberId,
                    this.uberEatsId = driver.data.data.uberEatsId,
                    this.deligoId = driver.data.data.deligoId,
                    this.freeId = driver.data.data.freeId,
                    this.street = driver.data.data.street,
                    this.streetExtraInfo = driver.data.data.streetExtraInfo,
                    this.city = driver.data.data.city,
                    this.licenceNumber = driver.data.data.licenceNumber,
                    this.abroadBankAccount = driver.data.data.abroadBankAccount,
                    this.cashPayments = driver.data.data.cashPayments,
                    this.accountNo = driver.data.data.accountNo,
                    this.paymentSchedule = driver.data.data.paymentSchedule,
                    this.cooperationType = driver.data.data.cooperationType,
                    this.carMake = driver.data.data.carMake
                    this.carPlate = driver.data.data.carPlate
                    this.zusType = driver.data.data?.zusType
                this.isReposession = driver.data.data.isReposession
                this.reposessionAccountNo = driver.data.data.reposessionAccountNo
                this.reposessionWireTitle = driver.data.data.reposessionWireTitle  
                this.reposessionAddressLine1 = driver.data.data.reposessionAddressLine1              
                this.reposessionAddressLine2 = driver.data.data.reposessionAddressLine2
                this.reposessionAddressLine3 = driver.data.data.reposessionAddressLine3
                this.reposessionAddressLine4 = driver.data.data.reposessionAddressLine4
                this.verifyAddress= driver.data.data.verifyAddress
                this.noAddress= driver.data.data.noAddress
            } catch (e) {
                console.log(e);
                this.dialogCreate = false;
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.dialogCreateLoading = false
            await this.toggleLoading(false);
        },  
        async deleteDriver(id) {
            await this.toggleLoading(true);
            try {
                Swal.fire({
                    title: "Usunąć Kierowcę?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await axios({
                            method: "delete",
                            url: process.env.VUE_APP_ROOT_API + "/drivers/" + id,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        });
                        await this.loadDrivers()
                    }
                });
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        },          
        async validateDriverForm() {            
            if (!this.firstName.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Imię'
                    }
                })
            }                      
            if (!this.lastName.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Nazwisko'
                    }
                })
            }
            if (!this.email.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij E-mail'
                    }
                })
            }
            if (!this.phone.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Telefon'
                    }
                })
            }
            if (!this.departament) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Oddział'
                    }
                })
            }            
            if(!this.noAddress){
                if (!this.street.trim().length) {
                    throw ({
                        response: {
                            statusText: 'Uzupełnij Adres'
                        }
                    })
                }
                if (!this.streetExtraInfo.trim().length) {
                    throw ({
                        response: {
                            statusText: 'Uzupełnij Adres 2'
                        }
                    })
                }
                if (!this.city.trim().length) {
                    throw ({
                        response: {
                            statusText: 'Uzupełnij Miasto'
                        }
                    })
                }
            }
            if (!this.cashPayments) {
                if (!this.accountNo.trim().length) {
                    throw ({
                        response: {
                            statusText: 'Uzupełnij Numer Konta'
                        }
                    })
                }else if(this.accountNo.trim().length!==26){
                    throw ({
                        response: {
                            statusText: 'Numer Konta musi posiadać 26 znaków'
                        }
                    })                    
                }
            }
            if (!this.paymentSchedule.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Częstotliwość Rozliczeń'
                    }
                })
            }
            if (!this.cooperationType.trim().length) {
                throw ({
                    response: {
                        statusText: 'Uzupełnij Sposób Rozliczeń'
                    }
                })
            }
        },    
        async previousStep(num){
        num = parseInt(num);    
            if(num>1){
                this.currentStep = num-1
            }else{
                this.currentStep = 1
            } 
        }, 
        async saveStep(num){
        num = parseInt(num);      
            if(this.currentStep<7){
                this.currentStep = num+1
            }else{
                this.currentStep =7
            }        
        },             
        async createDriver() {
            await this.toggleLoading(true);
            try {
                await this.validateDriverForm()
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/accept-driver/" + this.currentDriverId,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        password: this.password ?( this.password.trim().length > 0 ? this.password : null):null,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        phone: this.phone,
                        departament: this.departament,
                        boltId: this.boltId||null,
                        uberId: this.uberId || null,
                        deligoId: this.deligoId || null,
                        uberEatsId: this.uberEatsId || null,
                        isUnder26: this.isUnder26,
                        isStudent: this.isStudent,
                        isEmployed: this.isEmployed,
                        freeId: this.freeId || null,
                        street: this.street,
                        streetExtraInfo: this.streetExtraInfo,
                        city: this.city,
                        licenceNumber: this.licenceNumber,
                        abroadBankAccount: this.abroadBankAccount,
                        cashPayments: this.cashPayments,
                        accountNo: this.accountNo,
                        paymentSchedule: this.paymentSchedule,
                        cooperationType: this.cooperationType,
                        carMake: this.carMake,
                        carPlate: this.carPlate,  
                        isReposession: this.isReposession,                           
                        reposessionAccountNo: this.reposessionAccountNo,  
                        reposessionWireTitle: this.reposessionWireTitle,
                        reposessionAddressLine1: this.reposessionAddressLine1,
                        reposessionAddressLine2: this.reposessionAddressLine2,
                        reposessionAddressLine3: this.reposessionAddressLine3,
                        reposessionAddressLine4: this.reposessionAddressLine4,
                        verifyAddress: this.verifyAddress,
                        noAddress: this.noAddress,                                                               
                    },
                });
                this.firstName = ''
                this.lastName = ''
                this.email = ''
                this.phone = ''
                this.departament = null
                this.zusType = null
                this.boltId = ''
                this.uberId = ''
                this.isEmployed = false
                this.isReposession = false
                this.reposessionAccountNo = ''
                this.reposessionWireTitle = ''
                this.isUnder26 = false
                this.isStudent = false
                this.uberEatsId = ''
                this.freeId = ''
                this.street = ''
                this.streetExtraInfo = ''
                this.city = ''
                this.licenceNumber = ''
                this.abroadBankAccount = false
                this.cashPayments = false
                this.accountNo = '';
                this.password = '';
                this.carMake = '';
                this.carPlate = '';    
                this.reposessionAddressLine1 = '';                 
                this.reposessionAddressLine2 = '';
                this.reposessionAddressLine3 = '';
                this.reposessionAddressLine4 = '';
                this.paymentSchedule = null
                this.cooperationType = null
                this.dialogCreate = false
                this.verifyAddress = false
                this.noAddress = true  
            } catch (e) {                
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
            await this.loadDrivers()
        },               
        async loadDrivers() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options                
                const drivers = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/drivers/to-accept?sort=${sortBy.length ? sortBy : 'lastName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                drivers.data.data.items.forEach((item)=>{
                    item.created_at = dayjs(item.created_at).format('YYYY-MM-DD')
                    switch (item.formEnumValue){
                        case "imdriver":
                            item.formEnumValue = "Jestem Kierowcą"
                            break;
                        case "recomendation":
                            item.formEnumValue = "Z Polecenia"
                            break;
                        case "other_partner":
                            item.formEnumValue = "Inny Partner"
                            break;
                        case "job_lookup":
                            item.formEnumValue = "Szukam Pracy"
                            break; 
                        case "deligo":
                            item.formEnumValue = "Deligo"
                            break;                      
                        case "deligo_silesia":
                            item.formEnumValue = "Deligo Śląsk"
                            break;                                                                                                                                            
                    }
                })
                this.drivers = drivers.data.data.items
                this.itemsPerPage = drivers.data.data.meta.itemsPerPage
                this.page = drivers.data.data.meta.currentPage
                this.totalRecords = drivers.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },    
        async getDepartamentSelect() {
            await this.toggleLoading(true);
            try {
                let dep = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/departaments/list-departaments?itemsPerPage=100&sort=departamentName&direction=false",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.departaments = dep.data.data.items;
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        },             
    }
}
</script>
