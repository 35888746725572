<template>
    <v-card>
        <v-card-title>
            Faktury do Akceptacji
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table :headers="headers" :items="invoices" :options.sync="options" :server-items-length="totalRecords"
            :loading="loading" class="elevation-1">
                <template v-slot:item.actions="{ item }">
                    <v-btn class="warning" @click="editInvoice(item.id)">
                        <v-icon small class="pt-1">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="error" @click="deleteInvoice(item.id)">
                        <v-icon small class="pt-1">mdi-delete</v-icon>
                    </v-btn>
                </template>
        </v-data-table>
        <v-dialog :retain-focus="false" v-model="dialogInvoiceCost" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Edycja Faktury Kosztowej</span>
                    <v-spacer></v-spacer>
                    <v-btn class="secondary float-right" @click="showInvoice()">Pokaż Fakturę</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-from>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Kwota Brutto na Fakturze" v-model="invoiceCostPrice"
                                    placeholder="Kwota Brutto na Fakturze" :rules="[(v) => !!v || 'Uzupełnij Pole']">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Opis" v-model="invoiceCostDescription" placeholder="Opis"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select label="Stawka VAT" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="taxType"
                                    :items="taxTypes" item-text="text" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Tydzień Rozliczeniowy" v-model="invoiceWeekStr" type="week"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-from>
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogInvoiceCost = false">
                        Anuluj
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="createCostInvoice()">
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'

export default {
    name: "Missing",
    data: () => ({
        dialogInvoiceCost:false,
        invoiceCostPrice:0,
        invoiceCostDescription:'',
        invoiceWeekStr:'',
        currentInvoiceId:'',
        taxType: null,
        taxTypes: [
            {
                id:23,
                text:"23 %"
            },
            {
                id:8,
                text:"8 %"
            },            
        //     {
        //     id: 'tax_vat_fuel_range_1',
        //     text: 'I Stopień - Paliwo'
        // },
        // {
        //     id: 'tax_vat_fuel_range_2',
        //     text: 'II Stopień - Paliwo'
        // },
        // {
        //     id: 'tax_vat_fuel_range_3',
        //     text: 'III Stopień - Paliwo'
        // },
        // {
        //     id: 'tax_vat_full_range_1',
        //     text: 'I Stopień - Inne'
        // },
        // {
        //     id: 'tax_vat_full_range_2',
        //     text: 'II Stopień - Inne'
        // },
        // {
        //     id: 'tax_vat_full_range_3',
        //     text: 'III Stopień - Inne'
        // }
        ],                      
        options: {},
        totalRecords: 0,
        loading: false,
        headers: [
            { text: 'Data Dodania', value: 'created_at' },
            { text: 'Kierowca', value: 'driverName' },            
            { text: "", value: "actions", sortable: false },   
        ],
        invoices: [],        
    }),
    watch: {
        options: {
            async handler() {
                await this.loadInvoices()
            },
            deep: true,
        },
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async showInvoice(){
            const file = await axios({
                method: "post",
                url: process.env.VUE_APP_ROOT_API + `/invoices/show-document/${this.currentInvoiceId}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                responseType: 'blob'
            });
            let blob = new Blob([file.data], { type: file.data.type }),
            url = window.URL.createObjectURL(blob)
            window.open(url)                 
        },
        async loadInvoices() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options
                const invoices = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/invoices/get-to-edit?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                invoices.data.data.items.forEach((item) => {
                    item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')                    
                    item.driverName = item.driver.firstName + ' '+item.driver.lastName
                })
                this.invoices = invoices.data.data.items
                this.itemsPerPage = invoices.data.data.meta.itemsPerPage
                this.page = invoices.data.data.meta.currentPage
                this.totalRecords = invoices.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },
        async deleteInvoice(id){
            await this.toggleLoading(true);
            try {
                Swal.fire({
                    title: "Usunąć Fakturę?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) { 
                        await axios({
                            method: "delete",
                            url: process.env.VUE_APP_ROOT_API + `/invoices/delete-document/${id}`,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        });   
                    }
                }) 
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }  
            await this.toggleLoading(false);      
            await this.loadInvoices()   
                  
        },
        async editInvoice(id) {
            this.currentInvoiceId = id
            const invoices = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/invoices/get-document/${id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.invoiceCostPrice = (invoices.data.data.totalPrice / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            this.invoiceCostDescription = invoices.data.data.description
            this.dialogInvoiceCost = true
        },
        async createCostInvoice() {
            await this.toggleLoading(true)
            try {                
                if (!this.invoiceWeekStr.trim().length) {
                    throw ({ response: { statusText: 'Brak Wybranego Tygodnia!' } })
                }
                if (!this.invoiceCostPrice.trim().length) {
                    throw ({ response: { statusText: 'Brak Ceny!' } })
                }
                if (!this.invoiceCostDescription.trim().length) {
                    throw ({ response: { statusText: 'Brak Opisu Faktury!' } })
                }
                if (!this.taxType.trim().length) {
                    throw ({ response: { statusText: 'Brak Stawki Zwrotu Podatku!' } })
                }                
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/invoices/edit-invoice/"+this.currentInvoiceId,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        weekStr: this.invoiceWeekStr,
                        totalPrice: parseFloat(this.invoiceCostPrice),
                        description: this.invoiceCostDescription,
                        taxRange: this.taxType,
                    },
                });
                this.invoiceCostFile = null
                this.invoiceCostPrice = 0
                this.invoiceWeekStr = ''
                this.invoiceCostDescription = ''
                this.taxType = null
                this.dialogInvoiceCost = false
                this.currentInvoiceId = null
                await this.loadInvoices()                
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false)
        },          
    },
}
</script>
