import Vue from 'vue'
import VueRouter from 'vue-router'
// import Dashboard from '../views/Dashboard.vue'
import Config from '../views/Config.vue'
import Dkv from '../views/Dkv.vue'
import Cars from '../views/Cars.vue'
import Users from '../views/Users.vue'
import DriverDetails from '../views/DriverDetails.vue'
import Settlements from '../views/Settlements.vue'
import Import from '../views/Import.vue'
import Login from '../views/Login.vue'
import Payments from '../views/Payments.vue'
import ImportHistory from '../views/ImportHistory.vue'
import Missing from '../views/Missing.vue'
import Invoices from '../views/Invoices.vue'
import Receipts from '../views/Receipts.vue'
import DriverToAccapt from '../views/DriversToAccept.vue'
import ImportPreview from '../views/ImportPreview.vue'
import Reposession from '../views/Reposession.vue'
import DriversToVerify from '../views/DriversToVerify.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: Dashboard
  // }, 
  {
    path: '/reposession',
    name: 'Reposession',
    component: Reposession
  }, 
  {
    path: '/drivers-to-verify',
    name: 'DriversToVerify',
    component: DriversToVerify
  },     
  {
    path: '/import-preview',
    name: 'ImportPreview',
    component: ImportPreview
  },  
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts
  },  
  {
    path: '/settlements',
    name: 'Settlements',
    component: Settlements
  }, 
  {
    path: '/import',
    name: 'Import',
    component: Import
  },    
  {
    path: '/users',
    name: 'Users',
    component: Users
  },  
  {
    path: '/drivers',
    name: 'DriverDetails',
    component: DriverDetails
  }, 
  {
    path: '/drivers-to-accept',
    name: 'DriverToAccapt',
    component: DriverToAccapt
  },     
  {
    path: '/dkv',
    name: 'Dkv',
    component: Dkv
  }, 
  {
    path: '/cars',
    name: 'Cars',
    component: Cars
  },    
  {
    path: '/config',
    name: 'Config',
    component: Config
  }, 
  {
    path: '/payments',
    name: 'Payments',
    component: Payments
  }, 
  {
    path: '/import-history',
    name: 'ImportHistory',
    component: ImportHistory
  },   
  {
    path: '/import-missing',
    name: 'Missing',
    component: Missing
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices
  },           
  {
    path: '/login',
    name: 'Login',
    component: Login
  }    
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && localStorage.getItem('user')!='true') next({ name: 'Login' })
  else {
    if(to.fullPath=='/'){
      let role = localStorage.getItem('role');
      if(role!='admin'){
        location.href='/settlements'
      }else{
        location.href='/settlements'
      }      
    }else{
      next()
    }    
  }
})
export default router
