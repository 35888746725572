<template>
    <v-card>
        <v-card-title>
          Komornicy
          <v-spacer></v-spacer>                    
        </v-card-title>
        <v-data-table :headers="headers" :items="drivers" :loading="loading" class="elevation-1">
        </v-data-table>
      </v-card> 
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";
export default {
    name: "ToVerify",
    data: () => ({
        loading:false,
        drivers:[],
        headers: [
            {
                text: 'Imię',
                align: 'start',
                value: 'firstName',
            },
            {
                text: 'Nazwisko',
                align: 'start',
                value: 'lastName',
            }, 
            {
                text: 'Email',
                align: 'start',
                value: 'email',
            },                                                                                                                                   
        ],         
    }),
    async created(){
        await this.loadReposession()
    },
    methods:{
        async loadReposession(){
            this.loading = true
            try{
                let drivers = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/get-to-verify",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.drivers =drivers.data.data
            }catch(e){
                console.log(e);
                this.dialog = false;
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false            
        }
    }
}
</script>